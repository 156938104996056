import { createTheme } from '@material-ui/core/styles';
// https://dribbble.com/shots/10539027-Data-Pipeline-Dashboard-Dark/attachments/2341775?mode=media
// https://www.behance.net/gallery/92579887/Data-Pipeline-App

const fontSize = 10;
const htmlFontSize = 16;
const coef = fontSize / 14;
const sizeCoef = coef / htmlFontSize;

// Create a theme instance.
const darkTheme = createTheme({
  palette: {
    type: 'dark',
    primary: {
      main: '#00FFFF'
    },
    secondary: {
      main: '#e0e0e0',
      dark: '#bdbdbd'
    },
    alert: {
      main: '#f44336'
    },
    background: {
      default: '#212121',
      paper: '#424242'
    },
    info: {
      main: '#000'
    },
    chart: {
      plotly: {
        textColor: '#ffffff',
        gridColor: '#222222',
        backgroundHighlight: '#000000'
      },
      selectContainer: 'rgba(33, 33, 33, 0.9)',
      background: 'rgba(33, 33, 33, 0.3)',
      container: {
        gradient: 'linear-gradient(180deg, rgba(189,189,189,1) 0%, rgba(66,66,66,1) 60%, rgba(33,33,33,1) 100%)',
        inverseGradient: 'linear-gradient(0deg, rgba(139,139,139,1) 0%, rgba(46,46,46,1) 60%, rgba(13,13,13,1) 100%)'
      }
    }
  },
  typography: {
    fontSize
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#242531'
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#242531'
      }
    },
    MuiIconButton: {
      label: {
        color: '#00FFFF'
      }
    },
    MuiDivider: {
      root: {
        backgroundColor: '#00FFFF'
      },
      light: {
        backgroundColor: 'rgba(0, 255, 255, 0.1)'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: `${16 * sizeCoef}rem`
      }
    },
    MuiCard: {
      root: {
        backgroundColor: '#242531'
      }
    },
    MuiTab: {
      labelIcon: {
        minHeight: '50px',
        paddingTop: '0'
      }
    },
    MuiTableCell: {
      root: {
        padding: '10px'
      }
    },
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        backgroundColor: '#212121'
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: '#212121',
        fontSize: 12
      },
      sortAction: {
        fontSize: 12
      }
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: '#212121'
      }
    },
    MuiTableCell: {
      body: {
        fontSize: 11
      }
    },
    MuiAvatar: {
      circular: {
        width: 24,
        height: 24
      }
    }
  }
});

export default darkTheme;
