import { createTheme } from '@material-ui/core/styles';
import teal from '@material-ui/core/colors/teal';

const fontSize = 10;
const htmlFontSize = 16;
const coef = fontSize / 14;
const sizeCoef = coef / htmlFontSize;

const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#212121'
    },
    secondary: {
      light: '#bbdefb',
      main: '#3f51b5',
      dark: '#1a237e'
    },
    background: {
      default: '#eceff1',
      paper: '#fff'
    },
    info: {
      main: '#f5f5f5'
    },
    alert: {
      main: '#f44336'
    },
    chart: {
      plotly: {
        textColor: '#000000',
        gridColor: '#cccccc',
        backgroundHighlight: 'rgba(33, 33, 33, 0.3)'
      },
      selectContainer: 'rgba(33, 33, 33, 0.1)',
      background: 'rgba(33, 33, 33, 0.1)',
      container: {
        gradient: 'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(196,242,240,1) 60%, rgba(181,255,250,1) 100%)',
        inverseGradient:
          'linear-gradient(180deg, rgba(255,255,255,1) 0%, rgba(196,242,240,1) 60%, rgba(181,255,250,1) 100%)'
      }
    }
  },
  typography: {
    fontSize
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundColor: '#eceff1'
      }
    },
    MuiDrawer: {
      paper: {
        backgroundColor: '#eceff1'
      }
    },
    MuiTooltip: {
      tooltip: {
        fontSize: `${16 * sizeCoef}rem`
      }
    },
    MuiCard: {
      root: {
        backgroundColor: '#e0f2f1'
      }
    },
    MuiTab: {
      labelIcon: {
        minHeight: '50px',
        paddingTop: '0'
      }
    },
    MuiTableCell: {
      root: {
        padding: '10px'
      }
    },
    MUIDataTable: {
      paper: {
        boxShadow: 'none',
        backgroundColor: '#eceff1'
      }
    },
    MUIDataTableHeadCell: {
      fixedHeader: {
        backgroundColor: '#eceff1',
        fontSize: 12
      },
      sortAction: {
        fontSize: 12
      }
    },
    MUIDataTableSelectCell: {
      headerCell: {
        backgroundColor: '#eceff1'
      }
    },
    MuiTableCell: {
      body: {
        fontSize: 11
      }
    },
    MuiAvatar: {
      circular: {
        width: 24,
        height: 24
      }
    }
  }
});

export default lightTheme;
